import React from 'react';
import PropTypes from 'prop-types';

import CompanySection from './CompanySection';
import ImageSection from '../ImageSection';
import FadeUp from './FadeUp';

const CompaniesHeaderSection = ({
  title,
  description,
  image,
  imageAlt,
  video,
}) => (
  <>
    <CompanySection
      dark
      className="companies-header-section"
    >
      <ImageSection style={{ padding: '0' }}>
        <FadeUp>
          <div className="content">
            <h1 className="header">
              {title}
            </h1>
            <p className="description">
              {description}
            </p>
          </div>
        </FadeUp>
        <FadeUp>
          <div className="media">
            <iframe
              src="https://player.vimeo.com/video/989678675?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Case Videomatik"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '16px',
              }}
            />
          </div>
        </FadeUp>
      </ImageSection>
    </CompanySection>
    <style jsx global>
      {`
        section.company-section.company-section.companies-header-section {
          background: var(--gradient-red);
          padding-top: 150px;

          @media screen and (max-width: 1024px) {
            padding-top: 80px;
            text-align: center;
          }

          .content {
            max-width: 50vw;
            padding: 0 1em;

            @media screen and (max-width: 1024px) {
              max-width: unset;
            }
          }

          .content > .header {
            font-weight: 600;
            font-size: 4em;
            margin-bottom: 1.5rem;

            @media screen and (max-width: 1024px) {
              font-size: 2.5em;
            }
          }

          .content > .description {
            font-size: 2rem;
            margin-bottom: 5rem;

            @media screen and (max-width: 1024px) {
              font-size: 1.5em;
              margin-bottom: 2rem;
            }
          }

          .content > .contact-button {
            font-size: 1.6em;
            margin: 0;

            @media screen and (max-width: 1024px) {
              font-size: 1.4em;
            }
          }

          .media {
            --image-max-size: 700px;
            width: calc(min(800px, 80vw));
            aspect-ratio: 16 / 9;

            @media screen and (max-width: 1366px) {
              width: calc(min(600px, 80vw));
            }

            @media screen and (max-width: 500px) {
              --image-max-size: 300px;
            }

            @media screen and (max-width: 1024px) {
              padding-bottom: 1em;
            }
          }

          .media img, .media video {
            border-radius: var(--box-border-radius);
          }
        }
        `}
    </style>
  </>
);

CompaniesHeaderSection.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  video: PropTypes.string,
};

export default CompaniesHeaderSection;
