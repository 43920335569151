import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

export default function Meta({
  title = 'Videomatik - API para produção de vídeos personalizados',
  description = 'Automatize e escale a sua produção de vídeos personalizados com a Videomatik. Integração com o seu software e com seus templates de vídeo.',
}) {
  return (
    <Head>
      <title>{ title }</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://videomatik.com.br/" />
      <meta property="og:site_name" content="Videomatik" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://videomatik.com.br/images/preview.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="pt_BR" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@videomatik_br" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="facebook-domain-verification" content="3ds4oil778cy4ej6smgamaxkhk8lhp" />
      <link rel="shortcut icon" href="/icons/icon-48x48.png" />
      <link rel="manifest" href="/manifest.webmanifest" />
      <meta name="theme-color" content="#aa1e0d" />
      <link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/icons/icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="192x192" href="/icons/icon-192x192.png" />
      <link rel="apple-touch-icon" sizes="256x256" href="/icons/icon-256x256.png" />
      <link rel="apple-touch-icon" sizes="384x384" href="/icons/icon-384x384.png" />
      <link rel="apple-touch-icon" sizes="512x512" href="/icons/icon-512x512.png" />
    </Head>
  );
}

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
