import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'semantic-ui-react';
import classnames from 'classnames';

const AboutUsSection = ({ dark }) => (
  <section id="sobre" className={classnames('about-us-section', { '-dark': dark })}>
    <h2>Sobre Nós</h2>
    <p className="header">
      O Videomatik é uma joint venture da
      {' '}
      <b>
        <a
          href="https://www.ozprodutora.com.br/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Oz Produtora
        </a>
      </b>
      {' '}
      e da
      {' '}
      <b>
        <a
          href="https://kassellabs.io/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Kassel Labs
        </a>
      </b>
      .
    </p>
    <div className="container">
      <div className="founder">
        <img
          className="logo"
          src={dark ? '/logo/oz-logo-white.svg' : '/logo/oz-logo.svg'}
          alt="Logo Oz"
        />
        <p className="description">
          A
          {' '}
          <span style={{ fontWeight: 'bold' }}>Oz Produtora</span>
          {' '}
          é uma consultoria criativa em soluções audiovisuais que
          está há 20 anos no mercado. Com mais de 2000 Jobs concluídos
          e centenas de empresas clientes,
          já fizeram produções para grandes marcas e eventos como: AstraZeneca,
          Volkswagen, EPTV, CCXP, Arteris, Unimed, Latam Airlines e muitas outras.
        </p>
      </div>
      <div className="founder">
        <img className="logo" src="/logo/kassellabs-logo.svg" alt="Logo Kassel Labs" />
        <p className="description">
          A
          {' '}
          <span style={{ fontWeight: 'bold' }}>Kassel Labs</span>
          {' '}
          é uma empresa de tecnologia focada em automatização
          da produção de vídeos há mais de 6 anos.
          Ao longo de sua história, já produziu mais de um milhão de
          vídeos atendendo a todos os países do mundo,
          incluindo colaboradores de grandes empresas como
          Disney, Google, Tesla, NASA e muitas outras.
        </p>
      </div>
    </div>
    <div className="social-icons">
      {/* <a href={process.env.DISCORD_URL} rel="noopener noreferrer" target="_blank">
        <Icon name="discord" size="huge" />
      </a> */}
      {/* <a href="https://www.facebook.com/videomatik.com.br/" rel="noopener noreferrer" target="_blank">
        <Icon name="facebook" size="huge" />
      </a> */}
      <a href="https://instagram.com/videomatik.com.br" rel="noopener noreferrer" target="_blank">
        <Icon name="instagram" size="huge" />
      </a>
      <a href="https://www.youtube.com/channel/UC6fNE5udhHmZdI7x-jLDkLA" rel="noopener noreferrer" target="_blank">
        <Icon name="youtube" size="huge" />
      </a>
      <a href="https://linkedin.com/company/videomatik-br" rel="noopener noreferrer" target="_blank">
        <Icon name="linkedin" size="huge" />
      </a>
    </div>
    <p className="message">
      Criado com
      {' '}
      <img
        src="/images/heart-icon.png"
        alt="Amor"
        style={{ position: 'relative', top: '3px' }}
      />
      {' '}
      em São Carlos.
    </p>
    <style jsx>
      {`
      .about-us-section {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 80px 80px 20px;

        &.-dark {
          color: #F7F7F7;
          background: #3C3C3C;
          --color-anchor: var(--color-secondary);
          --color-anchor-hover: var(--button-secondary-hover-color);

          :global(.icon) {
            color: #F7F7F7;
          }
        }

        h2 {
          font-size: 3em;
          margin-bottom: 0.5em;
        }

        p {
          font-size: 1.3em;

          @media screen and (max-width: 1024px) {
            font-size: 1em;
          }
        }

        > .header {
          text-align: center;
          font-size: 1.5em;
          padding: 0 24px;

          @media screen and (max-width: 1024px) {
            font-size: 1.2em;
          }
        }

        > .container {
          display: flex;
          justify-content: space-evenly;

          @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: center;
          }
        }

        > .container > .founder {
          display: inline-flex;
          align-items: center;
          flex-direction: column;
          padding: 24px;
          width: 50%;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }

        > .container > .founder > .logo {
          height: 60px;
          margin-bottom: 24px;

          @media screen and (max-width: 1024px) {
            height: 50px;
            max-width: 90vw;
            margin-bottom: 12px;
          }
        }

        > .container > .founder > .description {
          text-align: justify;
          max-width: 600px;
        }

        @media screen and (max-width: 1024px) {
          padding: var(--landing-page-padding);
        }

        > .social-icons {
          display: flex;
          gap: 0.4em;
        }

        :global(> .social-icons .icon) {
          margin: 0;
        }

        > .message {
          padding-top: 1em;
        }
      }
      `}
    </style>
  </section>
);

AboutUsSection.propTypes = {
  dark: PropTypes.bool,
};

export default AboutUsSection;
