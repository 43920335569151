import React from 'react';
import PropTypes from 'prop-types';

export default function CompanySectionTitle({ children, style }) {
  return (
    <h1 className="company-section-title" style={style}>
      { children }
      <style jsx>
        {`
        .company-section-title {
          font-size: 3em;
          margin-bottom: 1.5rem;

          @media screen and (max-width: 1024px) {
            font-size: 2.5em;
          }
        }
        `}
      </style>
    </h1>
  );
}

CompanySectionTitle.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
