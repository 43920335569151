import React from 'react';
import PropTypes from 'prop-types';

import { Fade } from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const fadeInUpLittle = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export default function FadeUp({ children, ...props }) {
  return (
    <Fade keyframes={fadeInUpLittle} triggerOnce cascade damping={0.05} {...props}>
      { children }
    </Fade>
  );
}

FadeUp.propTypes = {
  children: PropTypes.node,
};
