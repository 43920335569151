import React from 'react';
import PropTypes from 'prop-types';

export default function ImageSection({ children, style }) {
  return (
    <div className="image-section" style={style}>
      { children }
      <style jsx>
        {`
        .image-section {
          --gap-margin: 50px;
          --image-max-size: 500px;

          display: flex;
          justify-content: center;
          align-items: center;
          padding: 80px;

          :global(img, video) {
            max-width: var(--image-max-size);

            @media screen and (max-width: 1024px) {
              max-width: calc(min(90vw, 1400px)) !important;
            }
          }


          :global(> :last-child) {
            margin-left: var(--gap-margin);

            @media screen and (max-width: 1024px) {
              margin-left: 0;
            }
          }

          :global(> :last-child) {
            @media screen and (max-width: 1024px) {
              margin-top: 1em;
            }
          }

          @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            padding: var(--landing-page-padding);
          }
        }
        `}
      </style>
    </div>
  );
}

ImageSection.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
