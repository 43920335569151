import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function CompanySection({
  dark, className, style, children,
}) {
  return (
    <section className={classnames('company-section', { '-dark': dark }, className)} style={style}>
      { children }
      <style jsx>
        {`
        .company-section {
          padding: var(--landing-page-desktop-padding);
          background: #F7F7F7;
          color: #3C3C3C;

          &.-dark {
            color: #F7F7F7;
            background: #3C3C3C;
          }

          @media screen and (max-width: 1024px) {
            padding: var(--landing-page-mobile-padding);
          }
        }
        `}
      </style>
    </section>
  );
}

CompanySection.propTypes = {
  dark: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};
